import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout";
import LeadsTable from "../../components/leads-table";
import useFetchData from "../../components/use-fetch-data";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BrokerAssignmentComponent from "../../components/broker-assignment-component";
import AdminLayout from "../../components/admin-layout";
import InfoDisplayComponent from "../../components/info-display-component";
import { Button, Spinner } from "react-bootstrap";
import withRoleBasedRedirect from "../../components/with-role-based-redirects";
import { GatsbySeo } from "gatsby-plugin-next-seo";

const LeadInfoPage = () => {
	let queryString = "";
	if (typeof window !== "undefined") {
		queryString = window.location.search;
	}
	const urlParams = new URLSearchParams(queryString);
	const leadId = urlParams.get("leadId");
	const apiKey = process.env.GATSBY_MY_SECRET_API_KEY; // Ensure you're using the right environment variable for your API key

	const {
		data: dataBrokers,
		loading: loadingBrokers,
		error: errorBrokers,
	} = useFetchData("/.netlify/functions/fetchAirtableBrokers", apiKey);
	const {
		data: leadsData,
		loading: loadingLeads,
		error: errorLeads,
	} = useFetchData(
		`/.netlify/functions/fetchAirtableLeadById?id=${leadId}`,
		apiKey
	);

	// Since loading and error states are handled within each call, we don't need additional state hooks for them

	// You may need to adjust this if your data structure requires it
	const goBack = () => {
		window.history.back();
	};

	if (loadingBrokers || loadingLeads) {
		return (
			<AdminLayout>
				<div
					style={{ width: "100%", height: "100%" }}
					className="position-relative"
				>
					<div
						style={{ top: "50vh" }}
						className="position-absolute start-50 translate-middle"
					>
						<Spinner
							style={{ width: "6rem", height: "6rem" }}
							animation="border"
							role="status"
							variant="primary"
						>
							<span className="visually-hidden">Loading...</span>
						</Spinner>
					</div>

					<GatsbySeo title="Lead Details" language="en" noindex nofollow />
				</div>
			</AdminLayout>
		);
	}

	if (errorLeads || errorBrokers || !leadId) {
		return (
			<AdminLayout>
				<GatsbySeo title="Lead Details" language="en" noindex nofollow />
				<Row>
					<Col>
						<div className="p-5 bg-white" style={{ borderRadius: "14px" }}>
							<h2>Leads not found or invalid lead ID.</h2>
							<p>
								<Link to="/admin">Return</Link>
							</p>
						</div>
					</Col>
				</Row>
			</AdminLayout>
		);
	}

	return (
		<AdminLayout>
			<GatsbySeo title="Lead Details" language="en" noindex nofollow />
			<Row>
				<Col>
					<h1 className="mb-4">Lead Details</h1>
				</Col>
			</Row>
			<Row className="">
				<Col>
					<div className="p-5 bg-white" style={{ borderRadius: "14px" }}>
						<Row>
							<Col>
								<div className="d-flex mb-5 align-items-center">
									<h2>{leadsData.customerName}</h2>
									<div
										className="px-3 py-2 ms-4 Jakarta-Bold "
										style={{
											color: "#fff",
											textAlign: "center",

											borderRadius: "100px", // Rounded corners
											backgroundColor:
												leadsData.status === "Assigned" ? "#2DAF49" : "#1470D6",
										}}
									>
										{leadsData.status || "Not Assigned"}
									</div>
								</div>
							</Col>
						</Row>
						<Row>
							<Col>
								<h2 className="mb-2 fs-3">Insurance</h2>
							</Col>
						</Row>
						<Row className="pb-4">
							<Col lg={3}>
								<h3 className="text-schema-grey mt-3 text-capitalize fs-6">
									Category
								</h3>
								<p className="Jakarta-Bold text-heading-color">
									{leadsData.category}
								</p>
							</Col>
							<Col lg={3}>
								<h3 className="text-schema-grey mt-3 text-capitalize fs-6">
									Type
								</h3>
								<p className="Jakarta-Bold text-heading-color">
									{leadsData.cover}
								</p>
							</Col>
						</Row>
						<InfoDisplayComponent formData={JSON.parse(leadsData.formFields)} />
						<h2 className="mt-5 fs-3 mb-4">Brokers</h2>
						<BrokerAssignmentComponent
							leadId={leadsData.airtableId}
							allBrokers={dataBrokers}
						/>
						<div className="text-end">
							<Button
								size="lg"
								className=" px-5 me-3 py-3 mt-5 text-uppercase"
								variant="outline-heading-color border-heading-color"
								onClick={goBack}
							>
								Go back
							</Button>
						</div>
					</div>
				</Col>
			</Row>
		</AdminLayout>
	);
};

export default withRoleBasedRedirect(LeadInfoPage, {
	requiredRole: "Super Admin",
});
